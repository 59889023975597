



























































import { mapMutations, mapState } from 'vuex';
import ScannerMixin from '@/mixins/ScannerMixin';
import { EnumsActions, EnumsIcons } from '@/store/modules/globals/types';
import LastEdited from '@/components/ui/LastEdited.vue';
import RepositoryFactory from '@/services/RepositoryFactory';
import IBoxesRepository from '@/services/Repositories/Boxes/types';

const factory = new RepositoryFactory();

export default ScannerMixin.extend({
  name: 'ReboxScanner',
  components: {
    TheFabButton: () => import('@/components/ui/TheFabButton.vue'),
    BasePopup: () => import('@/components/ui/BasePopup.vue'),
    LastEdited,
  },
  data() {
    return {
      confirmReturnPopup: false,
      reboxId: '',
      boxStatus: '',
    };
  },
  computed: {
    ...mapState('lastEdited', ['editedBoxes']),
    boxesRepository(): IBoxesRepository {
      return factory.get('boxes') as IBoxesRepository;
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_TITLE', 'SET_ACTION', 'SET_ICON', 'CLEAR_BOXSTATES']),
    async getBoxStatus(reboxId: string) {
      const { data } = await (this as any).boxesRepository.getOne(reboxId);
      const { status } = data;
      return status;
    },
    routeToReturnViewForReBoxId(reboxId: string) {
      this.$router.push({
        name: 'ReboxReturn',
        params: {
          reboxId,
        },
      });
    },
    async reboxSearch(reboxId: string) {
      const status = await this.getBoxStatus(reboxId);
      this.boxStatus = status;
      if (status !== 'DELIVERED_TO_MERCHANT') {
        this.reboxId = reboxId;
        this.confirmReturnPopup = true;
        return;
      }
      this.routeToReturnViewForReBoxId(reboxId);
    },
  },
  created() {
    this.SET_TITLE('header.reboxScanner');
    this.SET_ACTION(EnumsActions.processes);
    this.SET_ICON(EnumsIcons.processes);
    this.CLEAR_BOXSTATES();
  },
});
